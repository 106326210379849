@import './utils/fonts.css';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, p, span, option, label {
  color: #333;
  font-family: 'Roboto Slab', serif;
}

a {
  text-decoration: none;
}

label {
  font-weight: 100;
}
ul {
  list-style: none;
}
input, textarea{
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 5px;
}

label, textarea, input{
  display: block;
  width: 100%;
  margin-bottom: 5px;
}