@font-face {
    font-family: 'RobotoThin';
    src: local('fonts'), url(./fonts/RobotoSlab-Thin.ttf) format('opentype');
}

@font-face {
    font-family: 'RobotoLight';
    src: local('fonts'), url(./fonts/RobotoSlab-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'RobotoRegular';
    src: local('fonts'), url(./fonts/RobotoSlab-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'RobotoSemiBold';
    src: local('fonts'), url(./fonts/RobotoSlab-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'RobotoBold';
    src: local('fonts'), url(./fonts/RobotoSlab-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'MarckScript';
    src: local('fonts'), url(./fonts/MarckScript-Regular.ttf) format('opentype');
}